// extracted by mini-css-extract-plugin
export var container = "layout-module--container--YM7Lx";
export var header = "layout-module--header--UmhLx";
export var container__main = "layout-module--container__main--3JgEA";
export var container__left = "layout-module--container__left--3Jhap";
export var container__middle = "layout-module--container__middle--1f74j";
export var container__right = "layout-module--container__right--3aGWk";
export var nav_list = "layout-module--nav_list--2He5W";
export var g13_link = "layout-module--g13_link--2QjjI";
export var link__active = "layout-module--link__active--33--2";
export var home_link = "layout-module--home_link--P4VeI layout-module--g13_link--2QjjI";
export var what_link = "layout-module--what_link--1kIlW layout-module--g13_link--2QjjI";
export var license_link = "layout-module--license_link--1OEkg layout-module--g13_link--2QjjI";
export var contact_link = "layout-module--contact_link--1Er37 layout-module--g13_link--2QjjI";