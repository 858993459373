import { Link } from 'gatsby'
import * as React from 'react'
import { contact_link, container, container__left, container__main, container__middle, header, home_link, license_link, link__active, nav_list, what_link } from './layout.module.css'

const Layout = ({ pageTitle, children }) => {
  return (
    <div className={container}>
      <title>{pageTitle}</title>

      <div className={header}>

      </div>

      <main className={container__main}>


        <div className={container__left}>
          <nav>
            <ul className={nav_list}>
              <li><Link to="/" className={home_link} activeClassName={link__active}>Home Page</Link></li>
              <li><Link to="/what" className={what_link} activeClassName={link__active}>What the ROM™ Does</Link></li>
              <li><Link to="/license" className={license_link} activeClassName={link__active}>License</Link></li>
              <li><Link to="/contact" className={contact_link} activeClassName={link__active}>Contact Us</Link></li>
            </ul>
          </nav>
        </div>

        <div className={container__middle}>
          {children}
        </div>

      </main>
    </div>
  )
}
export default Layout