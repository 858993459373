import * as React from "react"
import Layout from '../components/layout'

const ContactPage = () => {
  return (
    <Layout pageTitle="G13 Railway Operations Model - Contact Us">
      <p><strong>CONTACTING US</strong></p>
      <blockquote>
        <p><strong>Tel:</strong> 07 3229 8300</p>
        <p><strong>Fax:</strong> 07 3112 4145</p>
        <p><strong>Email:</strong> <a href="mailto:g13rom@interdynamics.com">g13rom@interdynamics.com</a></p>
        <p><strong>Address:</strong></p>
        <p>G13 ROM <br />
          c/o InterDynamics<br />
          320 Adelaide Street<br />
          Brisbane QLD 4000</p>
      </blockquote>
    </Layout>
  )
}

export default ContactPage
